import React from "react";
import { Link, HeadFC } from "gatsby";

import Background from "../components/Background";

import imgCorgi from "../assets/images/corgi.png";

const NotFoundPage = () => {
  return (
    <main className="flex items-center min-h-[100vh]">
      <div className="container mx-auto px-[10vw] text-center">
        <h1 className="font-serif text-6xl md:text-5xl lg:text-[6vw] leading-tight md:no-leading mb-2 text-brand">
          404
        </h1>
        <p className="text-md md:text-lg font-thin">
          Hmm, looks like that page doesn't exist.
        </p>
        <div className="my-10">
          <img
            className="w-48 h-48 mx-auto animate-rotate"
            src={imgCorgi}
            alt="Corgi"
          />
        </div>
        <Link
          to="/"
          className="border-2 border-blue-500 px-4 py-2 rounded hover:bg-blue-50 active:bg-blue-100"
        >
          Go home
        </Link>
      </div>
      <Background />
    </main>
  );
};

export default NotFoundPage;

export const Head: HeadFC = () => <title>Not found | SEstudio</title>;
